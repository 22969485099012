<template>
  <Modal
    :title="'Send Message'"
    :show-close-button="true"
    :maxWidth="700"
    :alignTitleLeft="true"
    :showCancelButton="false"
    :showSubmitButton="false"
    submit-text="Send"
    @close="$emit('close')"
    @submit="$emit('send')"
    :disable-submit-button="isSubscriptionReadOnly()"
  >
    <div class="container">
      <div class="d-flex-col mb-3">
        <div>
          <span
            >All client contacts and internal team will receive this
            message</span
          >
        </div>
        <div v-if="emailTemplates?.length" class="align-right">
          <label class="mt-2 mr-1" for="ddlTemplates">Templates</label>
          <button
            id="ddlTemplates"
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{
              emailTemplate && emailTemplate.title
                ? emailTemplate.title
                : "Choose template"
            }}
          </button>
          <div class="dropdown-menu">
            <a
              v-for="(template, index) in emailTemplates"
              :key="template?.title"
              class="dropdown-item"
              @click="chosenTemplateIndex = index"
              >{{ template?.title }}</a
            >
          </div>
        </div>
      </div>
      <div class="form-group">
        <text-editor
          :editorText="emailText"
          :placeholder="placeholder"
          @on-editor-change="onEditorChange"
        />
      </div>
      <div class="align-right d-flex justify-content-end">
        <div class="my-auto mx-3">
          <toggle-button
            :initial-toggle-value="updateStatus"
            :label="'Move to next stage after sending'"
            @on-toggle="onToggleChange"
          />
        </div>
        <button
          class="btn btn-info btn-lg w-25"
          type="button"
          @click="onSendButtonClick"
        >
          Send
        </button>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/ui/Modals/Modal.vue";
import TextEditor from "../TextEditor.vue";
import ToggleButton from "../ToggleButton.vue";
import textEditorCompilerMixin from "../../../mixins/textEditorCompiler";
import { getClientById } from "../../../apis/clients";

export default {
  name: "SendMessageModal",
  components: { Modal, TextEditor, ToggleButton },
  mixins: [textEditorCompilerMixin],
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    workflow: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emailText: "",
      updateStatus: false,
      placeholder: "Add text here",
      chosenTemplateIndex: -1,
      client: {},
    };
  },
  watch: {
    chosenTemplateIndex: function () {
      this.emailText = this.replacePlaceholdersHelper(
        this.emailTemplateInClientLanguage?.body,
        this.emailTemplateInClientLanguage?.language
      );
    },
  },
  computed: {
    emailTemplate() {
      return this.emailTemplates[this.chosenTemplateIndex ?? 0];
    },
    allEmailTemplates() {
      return (this.$store.getters.emailTemplates ?? [])
        .filter((template) => !["invite", "survey"].includes(template.type))
        .sort((a, b) => a.id - b.id);
    },
    emailTemplates() {
      return this.allEmailTemplates.filter((t) => !t.parent_id);
    },
    emailTemplateInClientLanguage() {
      const clientLanguage = this.client?.language ?? "en";
      const chosenEmailTemplateId = this.emailTemplate?.id;
      const templateVariants = this.allEmailTemplates.filter(
        (t) =>
          t.id === chosenEmailTemplateId ||
          t.parent_id === chosenEmailTemplateId
      );

      let template = templateVariants.find(
        (t) => t.language === clientLanguage
      );

      if (!template)
        // template in client language was not found, fall back on default language
        template = templateVariants.find((t) => t.language === "en");

      if (!template)
        // default to the parent template
        return this.emailTemplate;

      return template;
    },
  },
  methods: {
    onEditorChange(value) {
      this.emailText = this.replacePlaceholdersHelper(
        value,
        this.emailTemplateInClientLanguage?.language
      );
    },
    onSendButtonClick() {
      const payload = {
        workflowId: this.workflow?.id,
        stageId: this.model?.id,
        emailText: this.emailText,
        updateStatus: this.updateStatus,
      };
      this.$emit("send", payload);
    },
    replacePlaceholdersHelper(text, language) {
      return this.replacePlaceholders(
        text,
        this.workflow,
        this.client,
        this.model,
        this.project,
        false,
        false,
        language
      );
    },
    onToggleChange(new_value) {
      this.updateStatus = new_value;
    },
  },
  async mounted() {
    this.client = await getClientById(this.project?.client_id);
  },
};
</script>
